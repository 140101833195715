
import DMVector from "./DMVector.vue";

export default {
  name: "Questions",
  components: { DMVector},
  props: {
    questions: Object,
    surveyResponse: Object,
    answerQuestion: Function,
    index: Number
  }, 
  data() {
    return {
      submit: true,
      message: "",
      errorMessage: ""
    }
  },
  methods: {
    validateInput(event) {
      const { type, value } = event.target

      if ((!value || 0 === value.length || !value.trim()) && type !== "password") {
        this.submit = false
        this.errorMessage = "required"
        return
      }   
      if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value) && type === "email") {
        this.submit = false;
        this.errorMessage = "email is invalid"
        return 
      } else if (!this.isValidPhone(value) && type === "tel") {
        this.submit = false;
        this.errorMessage = "phone is invalid"
        return
      } else if (value.length !== 9 && type === "password") {
        this.submit = false;
        this.errorMessage = "invalid ssn"

      } else {
          this.submit = true
          this.errorMessage = ""
      }
    },

    isValidPhone(p) {
  var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
  var digits = p.replace(/\D/g, "");
  return phoneRe.test(digits);
},

    setDataType(type) {
      if (type === "string") return "text"
      if (type === "email") return "email"
      if (type === "phone") return "tel"
      if (type === "monetary") return "number"
      if (type === "ssn") return "text"
      return "text"
    }
  }
};
