<template>
  <section v-if="finalSubmission" class="main">
    <div class="card end">
      <svg
        class="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path
          class="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
      <h1>Thank you for your participation!</h1>
      <p>
        If our team has any additional questions or needs more information regarding the answers you provided, they will reach out directly via text, email, or phone call.
       
        <br/><br/>
         Additional documentation or clarification may be required based on answers provided. You should expect a response within 2-3 business days.
      </p>
    </div>
  </section>
</template>
<script>
export default {
  name: "Submission",
  props: {
    finalSubmission: Boolean,
  },
};
</script>
<style lang="scss">
$dmgreen: #388640;

.end {
  text-align: center;
  max-width: 350px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  animation-delay: 0.2s;
  stroke-miterlimit: 10;
  stroke: $dmgreen;
  fill: none;
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  animation-delay: 0.2s;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 5% auto;
  box-shadow: inset 0px 0px 0px $dmgreen;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.4s ease-in-out 0.4s both;
}
.checkmark__check {
  animation-delay: 0.2s;
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $dmgreen;
  }
}
</style>
