
import Questions from "./Questions.vue";
import Submission from "./Submission.vue";
//import config from "./../config";
import { QueryParams, ISurvey } from "./types";

export default {
  components: {
    Questions,
    Submission,
  },
  data() {
    return {
      host: location.host,
      loading: false,
      index: 0,
      surveyResponse: {},
      errorCode: "",
      errorMessage: "",
      questions: {} as ISurvey,
      questionLength: 0,
      completed: false,
      finalSubmission: false,
      answers: {},
      errorOnInput: false,
      error: {},
    };
  },
  mounted() {
    // Checks if Survey Hash is empty then makes an API call to "/surveys/unanswered/:uuid" using the account survey hash
    this.checkCurrentSurveyStatus();
  },
  methods: {
    checkCurrentSurveyStatus() {
      console.log(this.$hostname)
      const params: QueryParams = this.formatQueryParams();
      fetch(`${this.$hostname}/surveys/compliance-edd/questionnaire/check`, {
        method: "POST",
        body: JSON.stringify({
          AccountHash: params.a,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if(!res.Survey) {
            this.getInitialSurveyQuestions();
          } else {
            if (res.Survey.Hash.length > 0) {
              this.getInitialSurveyQuestions();
            } else {
              // if Survey Hash is empty then make API call to "/surveys/unanswered/:uuid" using the account survey hash
              this.setPreviousSurveyQuestions(res.Hash);
            }
          }
        })
        .catch((err) => console.log(err));
    },

    setPreviousSurveyQuestions(accountSurveyHash) {
      // Make API call to get remaining questions for in progress survey = /surveys/unanswered/:uuid
      fetch(`${this.$hostname}/surveys/unanswered/${accountSurveyHash}`)
        .then((res) => res.json())
        .then((res: ISurvey) => {
          if(res.Questions.length > 0 ){
            this.questions = res;
            this.questionLength = res.Questions.length;
          }
        })
        .catch((err) => console.error(err));
    },

    getInitialSurveyQuestions() {
      const params: QueryParams = this.formatQueryParams();
      fetch(`${this.$hostname}/surveys/questionnaire/${params.survey}`)
        .then((res) => res.json())
        .then((res: ISurvey) => {
          console.log(res)
          this.questions = res;
          this.questionLength = res.Questions.length;
        })
        .catch((err) => console.error(err));
    },

    startSurvey() {
      const params: QueryParams = this.formatQueryParams();
      const POSTRequest: any = {};
      if (params.c) {
        POSTRequest.ComplianceHash = params.c;
      }
      if (params.a) {
        POSTRequest.AccountHash = params.a;
      }

      fetch(`${this.$hostname}/surveys/questionnaire/${params.survey}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(POSTRequest),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.Code >= 400) {
            this.errorCode = res.Code;
            this.errorMessage = res.Message;
            return;
          }

          this.surveyResponse = res;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    answerQuestion(answer: string, hash: string) {
      this.answers = { answer, hash };
      this.nextQuestion();
    },

    nextQuestion() {
      let { hash, answer } = this.answers;

      if (hash && answer) {
        fetch(`${this.$hostname}/surveys/answer/${this.surveyResponse.Hash}`, {
          method: "POST",
          body: JSON.stringify({
            SurveyQuestionHash: hash,
            Answer: answer,
          }),
        })
          .then((res: Response) => {
            if (res.status === 200) {
              this.incrementQuestion();
              this.errorCode = "";
              this.errorMessage = "";
            }
          })
          .catch((err) => console.error(err));
      }
    },

    incrementQuestion(increment = 1) {
      const { Questions } = this.questions;
      if (this.index <= Questions.length - 1) {
        this.answers = {};
        this.index = this.index + increment;
      }

      if (this.index === Questions.length) {
        this.completed = true;
        this.finishSurvey();
      }
    },

    prevQuestion() {
      if (this.index !== 0) {
        this.index--;
        this.answers = {};
      }
    },

    formatQueryParams(): QueryParams {
      const uri = window.location.href.split("?");
      if (uri.length == 2) {
        const vars = uri[1].split("&");
        const getVars = {} as QueryParams;
        let tmp: any = "";
        vars.forEach(function(v) {
          tmp = v.split("=");
          if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
        });
        return getVars;
      }
    },

    finishSurvey() {
      const params: QueryParams = this.formatQueryParams();
      fetch(
        `${this.$hostname}/surveys/compliance-edd/finish/${this.surveyResponse.Hash}`,
        {
          method: "POST",
          body: JSON.stringify({
            AccountHash: params.a,
          }),
        }
      )
        .catch((err) => console.log(err));
    },
  },
};
